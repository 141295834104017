import React from 'react';
import {Box, Divider, Typography } from '@mui/material';
import { ThreeP } from '@mui/icons-material';

export const PrivacyPolicy = () =>{
  return (
    <Box sx={{textAlign: 'left', backgroundColor: "#FFFFFF", p: 2, borderRadius: 2}}>
      <Typography sx={{fontSize: 18, pb: 2}}>■宣言</Typography>
      <Typography sx={{pb: 1}}>一般社団法人日本中小企業金融サポート機構（以下「当機構」）は、ここに個人情報保護方針を定め公開し、個人情報の重要性を認識し、全従業員に周知徹底を図り、個人情報の保護に努めます。なお、当機構へのお申し込みにあたり、お客様には当プライバシーポリシーにご同意いただいたものとさせていただきますので予めご確認ください。</Typography>
      <Typography sx={{fontSize: 18, pb: 2}}>■個人情報の管理</Typography>
      <Typography sx={{pb: 1}}>当機構は、個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス、紛失、破損、改ざん及び漏洩などを防止するため、セキュリティシステムの維持、並びに管理体制の整備及び社員教育の徹底等必要な措置を講じ、安全対策を実施することにより、個人情報の厳重な管理を行います。</Typography>
      <Typography sx={{fontSize: 18, pb: 2}}>■個人情報の利用目的</Typography>
      <Typography sx={{pb: 1}}>当機構における個人情報の利用目的は、以下のとおりです。</Typography>
      <Typography sx={{pb: 1}}>なお、当機構はお客様とのご契約終了後や会員サイトの退会後も、以下の利用目的の範囲内で個人情報を利用することがあります。</Typography>
      <Typography sx={{pb: 1}}>①当機構のサービスのお申し込み、ご相談の受付のため</Typography>
      <Typography sx={{pb: 1}}>②ご本人様の確認や、当機構のサービスをご利用いただく資格等（反社会的勢力との関係を含みます）の確認のため</Typography>
      <Typography sx={{pb: 1}}>③当機構のサービスの提供にかかる妥当性判断のため</Typography>
      <Typography sx={{pb: 1}}>④お客様とのご契約について、当機構においてそのご契約の管理を適切に行うため</Typography>
      <Typography sx={{pb: 1}}>⑤ご契約終了後の照会への対応や法令等により必要となる管理を適切に行うため</Typography>
      <Typography sx={{pb: 1}}>⑥お客様とのご契約や法律等に基づく権利の行使や義務の履行のため</Typography>
      <Typography sx={{pb: 1}}>⑦譲受債権の管理・回収、権利行使、分析、査定のため</Typography>
      <Typography sx={{pb: 1}}>⑧電話によるご案内やダイレクトメールの発送等、サービスに関する各種ご案内のため</Typography>
      <Typography sx={{pb: 1}}>⑨各種リスク管理を適切に行うため</Typography>
      <Typography sx={{pb: 1}}>⑩お客様によりよいサービスを提供するため、よりご満足をいただくためのマーケティング分析に利用するため</Typography>
      <Typography sx={{pb: 1}}>⑪当機構や第三者の商品又はサービスに関する広告を配信若しくは表示するため</Typography>
      <Typography sx={{pb: 1}}>⑫関連会社との共同利用のため</Typography>
      <Typography sx={{pb: 1}}>⑬その他、お客様とのお取引を適切かつ円滑に履行するため</Typography>
      <Typography sx={{fontSize: 18, pb: 2}}>■個人データの第三者への提供について</Typography>
      <Typography sx={{pb: 1}}>当機構は、お預かりした個人データを適切に管理し、次のいずれかに該当する場合を除き、第三者に提供いたしません。</Typography>
      <Typography sx={{pb: 1}}>①法令に基づき開示する必要である場合</Typography>
      <Typography sx={{pb: 1}}>②裁判所及び警察等の公的機関、もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより、当該事務に支障を及ぼすおそれがある場合</Typography>
      <Typography sx={{pb: 1}}>③お客様や第三者の生命・身体・財産・名誉の保護のために必要な場合であって、ご本人の同意を得ることが困難である場合</Typography>
      <Typography sx={{pb: 1}}>④公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合</Typography>
      <Typography sx={{fontSize: 18, pb: 2}}>■個人データの共同利用</Typography>
      <Typography >当機構は、以下のとおり個人データを共同利用することがあります。</Typography>
      <Box component='ol' sx={{listStyleType: 'none'}}>
        <Box component='li' sx={{textAlign: 'left', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>&#9312;</Box>共同して利用する者の範囲
        </Box>
        <Box component='li' sx={{textAlign: 'left', ml: '18px', pb: 1}}>
          当機構の子会社及び関連会社 ※対象会社は、変動します。
        </Box>
        <Box component='li' sx={{textAlign: 'left', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>&#9313;</Box>共同利用者の利用目的
        </Box>
        <Box component='li' sx={{textAlign: 'left', ml: '18px', pb: 1}}>
          共同利用者は、以下の目的で利用します。
        </Box>
        <Box component='li' sx={{textAlign: 'left', ml: '32px', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>(1)</Box>当機構、子会社及び関連会社における譲受債権の管理・回収、権利行使、分析、査定、お客様との契約管理、リスクの掌握等、経営上必要な各種の管理を行うため
        </Box>
        <Box component='li' sx={{textAlign: 'left', ml: '32px', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>(2)</Box>お客様によりよい商品、サービスを提供し、よりご満足をいただくためのマーケティング分析や商品・サービス開発を行うため
        </Box>
        <Box component='li' sx={{textAlign: 'left', ml: '32px', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>(3)</Box>当機構、子会社及び関連会社のサービスのご紹介・ご提案のため
        </Box>
        <Box component='li' sx={{textAlign: 'left', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>&#9314;</Box>共同利用する個人データ項目
        </Box>
        <Box component='li' sx={{textAlign: 'left', ml: '32px', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>(1)</Box>属性情報（お名前、ご住所、生年月日、電話番号、メールアドレス等）
        </Box>
        <Box component='li' sx={{textAlign: 'left', ml: '32px', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>(2)</Box>取引情報（契約の種類、契約日、契約金額、履行状況等）
        </Box>
        <Box component='li' sx={{textAlign: 'left', ml: '32px', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>(3)</Box>譲受債権の情報（譲受債権の種類、金額、残高、支払状況等）
        </Box>
        <Box component='li' sx={{textAlign: 'left', ml: '32px', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>(4)</Box>収支関連情報（収入、資産、決算関連事項）
        </Box>
        <Box component='li' sx={{textAlign: 'left', ml: '32px', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>(5)</Box>共同利用者が一定の基準により査定を行った評価情報など
        </Box>
        <Box component='li' sx={{textAlign: 'left', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>&#9315;</Box>個人データの管理について責任を有する者
        </Box>
        <Box component='li' sx={{textAlign: 'left', ml: '18px', pb: 1}}>
          〒105-0012　東京都港区芝大門1-2-18-2F
        </Box>
        <Box component='li' sx={{textAlign: 'left', ml: '18px', pb: 1, fontSize: '12px'}}>
          （商業登記上の本店所在地：東京都中央区銀座六丁目６番１号）
        </Box>
        <Box component='li' sx={{textAlign: 'left', ml: '18px', pb: 1}}>
          一般社団法人日本中小企業金融サポート機構　代表理事　谷口亮
        </Box>
      </Box>
      <Typography sx={{fontSize: 18, pb: 2}}>■保有個人データに関する開示、訂正、利用停止等</Typography>
      <Typography sx={{pb: 1}}>①お客様は、お客様に関する当機構の保有個人データの開示を求めることができます。</Typography>
      <Typography sx={{pb: 1}}>②当機構の保有個人データの内容に誤りがある場合、お客様は当該保有個人データの内容の訂正、追加又は削除を求めることができます。</Typography>
      <Typography sx={{pb: 1}}>③当機構の保有個人データについて当機構が違法に取得又は違法に扱っている場合、お客様は当該保有個人データの利用の停止、消去を求めることができます。</Typography>
      <Typography sx={{pb: 1}}>④ お申出先は、以下のとおりです。</Typography>
      <Box component='ol' sx={{listStyleType: 'none'}}>
        <Box component='li' sx={{textAlign: 'left', pb: 1}}>
          〒105-0012　東京都港区芝大門1-2-18-2F
        </Box>
        <Box component='li' sx={{textAlign: 'left', pb: 1, fontSize: '12px'}}>
          （商業登記上の本店所在地：東京都中央区銀座六丁目６番１号）
        </Box>
        <Box component='li' sx={{textAlign: 'left', pb: 1}}>
          一般社団法人日本中小企業金融サポート機構
        </Box>
      </Box>
      <Typography sx={{pb: 1}}>info@chushokigyo-support.or.jp</Typography>
      <Typography sx={{pb: 1}}>（お申出は、ご本人、法定代理人、委任を受けた代理人に限りすることができます。お申出にあたっては、お客様ご本人であることの確認のため、免許証等の本人確認書類（代理人にあっては委任状及びご本人の印鑑証明書、成年被後見人にあっては登記事項証明書、未成年者にあっては本人の戸籍謄本）のご提出またはご提示が必要となります。）</Typography>
      <Typography sx={{fontSize: 18, pb: 2}}>■個人関連情報の受領</Typography>
      <Typography sx={{pb: 1}}>当機構は、その情報単体では個人情報に該当しない個人関連情報を第三者から取得しています。お客様が当機構のサービスの利用にあたり当機構に個人情報を提供した場合、当機構は、当該情報と、当該お客様の個人関連情報を紐付ける場合があります。この場合には当該個人関連情報も個人情報として取り扱います。</Typography>
      <Typography sx={{pb: 1}}>当機構は、個人関連情報を、当プライバシーポリシーにおける「個人情報の利用目的」と同様の目的に利用します。</Typography>
      <Typography sx={{pb: 1}}>ここで、個人関連情報とは、以下の情報を言います。</Typography>
      <Box component='ol' sx={{listStyleType: 'none'}}>
        <Box component='li' sx={{textAlign: 'left', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>1</Box>属性情報（名前、住所、生年月日、電話番号、メールアドレス等）
        </Box>
        <Box component='li' sx={{textAlign: 'left', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>2</Box>Cookie
        </Box>
        <Box component='li' sx={{textAlign: 'left', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>3</Box>IPアドレス
        </Box>
        <Box component='li' sx={{textAlign: 'left', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>4</Box>識別子
        </Box>
        <Box component='li' sx={{textAlign: 'left', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>5</Box>使用端末・ブラウザ（インターネット閲覧ソフト）
        </Box>
        <Box component='li' sx={{textAlign: 'left', pb: 1}}>
          <Box component='span' sx={{mr: '2px'}}>6</Box>位置情報・行動履歴といったインターネットの利用にかかるログ情報
        </Box>
      </Box>
      <Typography sx={{fontSize: 18, pb: 2}}>■個人情報の安全対策</Typography>
      <Typography sx={{pb: 1}}>当機構は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。</Typography>
      <Typography sx={{fontSize: 18, pb: 2}}>■ご本人の照会</Typography>
      <Typography sx={{pb: 1}}>お客様がご本人の個人情報の照会、修正または削除などを希望される場合には、ご本人であることを確認のうえ、対応させていただきます。</Typography>
      <Typography sx={{fontSize: 18, pb: 2}}>■法令、規範の遵守と見直し</Typography>
      <Typography sx={{pb: 1}}>当機構は、保有する個人情報に関して適用される日本の法令及びその他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善をいたします。</Typography>
      <Typography sx={{fontSize: 18, pb: 2}}>■お問い合わせ</Typography>
      <Typography sx={{pb: 1}}>当機構の個人情報の取扱に関しては、以下からお問い合わせください。</Typography>
      <Typography><Box component='a' href='https://chushokigyo-support.or.jp/inquiry/' target='_blank'>https://chushokigyo-support.or.jp/inquiry/</Box></Typography>
    </Box>
  );
};