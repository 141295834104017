import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { signUp } from "../../lib/api/auth";
import { useForm, Controller} from "react-hook-form";
import { Button, TextField, FormControlLabel, Checkbox, Grid, InputLabel, Box, Container, Link as MuiLink, Typography, InputAdornment, IconButton  } from '@mui/material';
import Loading from "../../components/Loading";
import Cookies from 'js-cookie';
import { ErrorModal } from "../../components/ErrorModal";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ReCAPTCHA from "react-google-recaptcha";

const validationRules = {
  email:{
    required: 'メールアドレスを入力してください',
    maxLength: {
      value: 80,
      message: '80文字以下で入力してください'
    },
    validate: (value, formValues) =>{
      const EmailVaildPattern = /^[a-zA-Z0-9._%+-/!#$%&'*=?^_`{|}~]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,4}$/;
      return EmailVaildPattern.test(value) || 'メールアドレスの形式が合っていません';
    }
  },
  password:{
    required: 'パスワードを入力してください',
    validate: (value, formValues) =>{
      const passwordPattern = /^([a-zA-Z0-9]{8,})$/
      const passwordPatternStr = /[a-zA-Z]/
      const passwordPatternNum = /[0-9]/
      if(!passwordPattern.test(value)){
        return 'パスワードは8文字以上の半角英数で入力してください'
      }
      if(!passwordPatternStr.test(value)){
        return 'パスワードには、半角英文字を含む必要があります'
      }
      if(!passwordPatternNum.test(value)){
        return 'パスワードには、半角数字を含む必要があります'
      }
      if(value != formValues.passwordConfirmation){
        return 'パスワード(確認用)と一致しません';
      }
    }
  },
  passwordConfirmation:{
    required: 'パスワード(確認用)を入力してください'
  }
}

export const Signup = () => {
  const { control, handleSubmit, setValue, getValues, setError} = useForm({defaultValues: {email: '', password: '', passwordConfirmation: '', isCheck: false}});
  const [isEnableSubmit, setIsEnableSubmit] = useState(true);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const ad = query.get('ad');
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState<any>(null);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const checkRef = useRef<HTMLButtonElement>(null);

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const handleSignUpSubmit = async (e) => {
    if(isLoading) return;
    setIsLoading(true);
    try {
      if(ad && search){
        e.portalregistrationparameters = ad
        e.portalregistrationparameterslong = search.slice(1);
      }
      e['recaptcha_token'] = recaptchaValue;
      const res = await signUp(e);
      setIsLoading(false);
      if(res.status == 200){
        navigate('/signup/email');
      }
    } catch (e) {
      setIsLoading(false);
      const response = e.response;
      if (recaptchaRef.current && recaptchaValue) {
        recaptchaRef.current.reset();
        onChangeRecaptcha(null);;
      }
      if(e.response.status == 422){
        // バリデーションエラー
        if(response.data.errors.email && response.data.errors.email[0].indexOf('すでに存在します')){
          setErrorMessage(<Typography>登録済みのユーザです</Typography>);
          handleOpenErrorModal();
        }
      }else{
        setErrorMessage(<Typography>{response.data.errors[0]}</Typography>);
        handleOpenErrorModal();
      }
    }
  };

  const handleChange = (e) =>{
    if(e.currentTarget.checked && recaptchaValue){
      setIsEnableSubmit(false);
    }else{
      setIsEnableSubmit(true);
    }
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onChangeRecaptcha  = (value: string | null) =>{
    setRecaptchaValue(value);
    if(checkRef?.current?.children[0]['checked'] && value){
      setIsEnableSubmit(false);
    }else{
      setIsEnableSubmit(true);
    }
  }

  return (
    <Container
      sx={{
        width: {md: '80%', xs: '95%'},
        pt: 5,
        pb: 5
      }}
    >
      <Box component='img' src="/flow.png" sx={{width: '100%', display: {xs: 'none', md: 'block'}}}/>
      <Box component='img' src="/flow_sp.png" sx={{width: '100%', display: {md: 'none'}}}/>
      <form id='sign_up_form' onSubmit={handleSubmit(handleSignUpSubmit)}>
        <Controller
          control={control}
          name='email'
          rules={validationRules['email']}
          render={({ field, fieldState }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
              <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                メールアドレス
              </InputLabel>
              </Grid>
              <Grid item md={9} sm={12} xs={12}>
              <TextField
                {...field}
                fullWidth
                sx={{mt: {xs: 0, md: 2}}}
                margin="normal"
                color="secondary"
                placeholder="入力してください"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
              </Grid>
            </Grid>
          )}
        />
        <Controller
          control={control}
          name='password'
          rules={validationRules['password']}
          render={({ field, fieldState }) => (
            <Box>
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    パスワード
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 0, md: 2}}}
                    type={showPassword ? 'text' : 'password'}
                    margin="normal"
                    color="secondary"
                    placeholder="半角英数字8文字以上"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    InputProps={{endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {!showPassword ? <VisibilityOff sx={{mr: {xs: 1, md: 0}}}/> : <Visibility sx={{mr: {xs: 1, md: 0}}}/>}
                        </IconButton>
                      </InputAdornment>
                    )}}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        />
        <Controller
          control={control}
          name='passwordConfirmation'
          rules={validationRules['passwordConfirmation']}
          render={({ field, fieldState }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                  パスワードの確認
                </InputLabel>
              </Grid>
              <Grid item md={9} sm={12} xs={12}>
                <TextField
                  {...field}
                  fullWidth
                  sx={{mt: {xs: 0, md: 2}}}
                  type={showPasswordConfirm ? 'text' : 'password'}
                  margin="normal"
                  color="secondary"
                  placeholder="半角英数字8文字以上"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  InputProps={{endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirm}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {!showPasswordConfirm ? <VisibilityOff sx={{mr: {xs: 1, md: 0}}}/> : <Visibility sx={{mr: {xs: 1, md: 0}}}/>}
                      </IconButton>
                    </InputAdornment>
                  )}}
                />
              </Grid>
            </Grid>
          )}
        />
        <Box sx={{display: 'flex', justifyContent: {xs: 'left', md: 'center'}}}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={"6LegixMqAAAAANqkeDwPrb91qndO-1hBGAznjgtw"}
            onChange={onChangeRecaptcha}
          />
        </Box>
        <Box sx={{textAlign: {xs: 'left', md: 'center'}}}>
          <FormControlLabel control={<Checkbox ref={checkRef} onChange={handleChange} />} label={
            <Box sx={{textAlign: {xs: 'left', md: 'center'}, fontSize: '14px'}}>
              <MuiLink color="secondary" underline="hover" component={Link} to='/privacy' target='_blank'>プライバシーポリシー</MuiLink>・<MuiLink color="secondary" underline="hover" component={Link} to='/terms_of_use' target='_blank'>利用規約</MuiLink>に同意のうえ登録に進む
            </Box>
          } />
        </Box>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
          disabled={isEnableSubmit}
        >
          送信
        </Button>
      </form>
      { Cookies.get('isSignedIn') && Cookies.get('isSignedIn') == 'true' ?
      <></>
      :
      <MuiLink
        component={Link}
        to="/login"
        underline="hover"
      >既にアカウントをお持ちの方</MuiLink>
      }
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </Container>
  );
};